.speech-container {

    .no-description {
        color: white;
        font-size: 21px;
        line-height: 28px;
        max-width: 725px;
        text-align: center;

        div {
            display: block;
        }
    }

    @media (max-width: 600px) {
        .no-description {
            font-size: 5vw;
        }
    }
}