body {
	font-family: "Roboto";
	background-color: #1b1c26;

	.header-container {
		width: 100%;
		height: 48px;
		border-bottom: 2px solid #414050;
	}

	.body-container {
		width: 100%;
		min-height: calc(100vh - 100px);
	}

	.footer-container {
		width: 100%;
		height: 48px;
		border-top: 2px solid #414050;
	}
}