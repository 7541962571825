.switch-button {
    width: auto;
    border-radius: 5px !important;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    color: white;
    padding: 0 4px;
    height: 37px;
    background-color: #00a4db;

    & > div {
        display: inline-block;
        text-align: center;
        width: 45px;
        vertical-align: top;
        font-size: 16px;
        margin-top: 5px;
        border-radius: 6px;
        padding: 2px 0px;
        font-weight: bold;

        &.active {
            background-color: white;
            color: #6a6e93;
        }
    }

}