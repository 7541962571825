header {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    background-color: #171825;
    color: white;
    padding: 0 24px;
    width: 100vw;

    .logo-container {
        max-height: 48px;
    }

    .options-container {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;

        & > div {
            margin-left: 16px;
        }
    }

    .language-selector-component .main {
        background-color: #1b1c26;

        &:hover {
            background-color: unset;
        }
    }

    @media (max-width: 500px) {
        & .login-button-container {
            display: none;
        }
    }
}