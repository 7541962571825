.spinner {
    text-align: center;
    margin-top: 32px;

    div {
        width: 60px;
        height: 60px;
    }

    .spinner-border {
        color: #3d6abc;
    }
}