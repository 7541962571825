.results-page {
    min-height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 100vw;

    .reload-button-container {
        margin-bottom: 30px;
    }

    @media(max-width: 1495px) {
        .reload-button-container {
            margin-top: 30px;
            margin-bottom: 10px;
        }
    }
}