.subtitles {
    height: 100%;
    background: #3f414e;
    box-shadow: 0 0 10px -1px #08090c;
    text-align: justify;
    padding: 16px;
    overflow-y: auto;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    min-height: 320px;

    .button-container {
        text-align: right;

        button {
            border: 0;
            border-radius: 2px;
            color: white;
            padding: 4px 15px;
            background-color: #00a4db;

            &:hover {
                opacity: 0.9;
            }
        }
    }

    .text {
        margin-top: 16px;
        max-height: 0;
        color: white;
    }
}