.title-container {

    .title {
        text-align: center;
        font-size: 40px;
        color: white;
    }

    @media (max-width: 600px) {
        .title {
            font-size: 8vw !important;
        }
    }
}