@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;600&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.syntube-gradient {
  background: rgb(0,164,219);
  background: linear-gradient(35deg, rgba(0,164,219,1) 15%, rgba(255,0,0,1) 100%);
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background: rgba(0,0,0,0.05);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 1px rgba(0,0,0,0.8);
  background-color: #3d6abc;
}

::-webkit-scrollbar-thumb:hover{
  background: linear-gradient("to right", #888, #777);
}