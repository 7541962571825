.hamburguer-menu {
    position: absolute;
    width: 100%;
    height: calc(100% - 100px);
    overflow: hidden;
    background-color: #171825;
    z-index: 9;
    left: 0;
    top: 48px;

    .wrapper {
        width: 600px;
        max-width: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
        font-size: 24px;

        .menu-login-phone {
            display: none;

            @media (max-width: 500px) {
                & {
                    display: inherit;
                }
            }
        }



        & > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 8px 0;
            border-bottom: 1px solid #414050;
        }

        .right {
            float: right;

            & > button {
                background-color: #00a4db;
                color: white;
                border-radius: 5px;
                border: 0px;
                font-size: 17px;
                font-weight: bold;
                width: 97px;
                padding: 4px 0;
            }
        }

        @media (max-width: 500px) {
            & {
                font-size: 7vw;
            }
        }

    }
    /*
    .wrapper {
        width: 600px;
        max-width: 100%;
        margin: auto;

        & > div {
            text-align: center;
            padding: 12px;
            border-bottom: 1px solid #414050;
            font-size: 24px;
            color: white;
            min-height: 62px;

            .right {
                float: right;

                & > button {
                    background: rgb(97,84,159);
                    background: -moz-linear-gradient(left,  rgba(97,84,159,1) 0%, rgba(62,101,188,1) 32%, rgba(61,124,191,1) 72%, rgba(98,149,175,1) 100%);
                    background: -webkit-linear-gradient(left,  rgba(97,84,159,1) 0%,rgba(62,101,188,1) 32%,rgba(61,124,191,1) 72%,rgba(98,149,175,1) 100%);
                    background: linear-gradient(to right,  rgba(97,84,159,1) 0%,rgba(62,101,188,1) 32%,rgba(61,124,191,1) 72%,rgba(98,149,175,1) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#61549f', endColorstr='#6295af',GradientType=1 );
                    color: white;
                    border-radius: 5px;
                    border: 0px;
                    font-size: 17px;
                    font-weight: bold;
                    width: 97px;
                    padding: 4px 0;
                }
            }
            
            .left {
                float: left;

                @media (max-width: 500px) {
                    & {
                        font-size: 4vw !important;
                    }
                }
            }
        }
    }*/
}