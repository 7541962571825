.logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 177px;

    img {
        width: 120px;
    }

    .beta-tag {
        font-weight: 600;
        background: #5e92b1;
        border-radius: 5px;
        font-size: 11px;
        padding: 3px 8px;
    }

    @media (max-width: 350px) {
        & {
            width: 100px;

            .beta-tag {
                margin-left: 10px;
            }
        }
    }

}