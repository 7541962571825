.login-button {
    background-color: #1b1c26;
    border: 1px solid #585858;
    border-radius: 4px;
    padding: 4px 8px;
    cursor: pointer;
    width: 95px;

    &:hover {
        background-color: unset;
    }

    img {
        margin-right: 6px;
    }
}

.google-logout {
    display: flex;
    flex-direction: row;
    align-items: center;

    .logout-button {
        margin-left: 16px;
        border: 1px solid #585858;
        font-size: 14px;
        border-radius: 4px;
        padding: 4px;
        cursor: pointer;
    }
}