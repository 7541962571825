.hamburguer {
    width: 39px;
    height: 34px;
    border: 1px solid #585858;
    border-radius: 2px;
    background-color: #1b1c26;
    cursor: pointer;

    &:hover {
        background-color: #171825;
    }

    & > div {
        margin-top: -8px;
        margin-left: -5px;

        & > div {
            width: 26px !important;
            margin-left: 3px !important;
        }
    }
}