footer {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    background-color: #171825;
    color: white;

    a {
        color: white;
        text-decoration: none;
    }
}

@media (max-width: 600px) {
    footer {
        font-size: 4vw;
    }
}