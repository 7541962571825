.metadata-container {
    width: 100vw;
    display: flex;
    flex-wrap:wrap; 

    & > div {
        margin: 16px;
        flex: 1;
        min-width: 700px;
    }

    .video-container {
        flex: 2;
    }

    @media (max-width: 600px) {
        & > div {
            margin: 16px 0;
        }
    }
}