.start-page {
    min-height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;

    .email-form-container {
        margin-top: 60px;
    }

    .video-form-container,
    .email-form-container,
    .spinner-container {
        height: 40vh;
    }
}