.reload-button button {
    color: #fff;
    font-size: 22px;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    font-weight: bold;

    &:hover {
        opacity: 0.9;
    }
}