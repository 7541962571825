.data-display {
    height: 100%;
    display: flex;
    flex-direction: column;

    .tabs button {
        color: white !important;
    }

    & > .description-container,
    & > .subtitles-container {
        margin-top: 16px;
        height: 100%;
        max-width: 100vw !important;
    }

    @media (max-width: 600px) {
        .tabs button {
            font-size: 3.5vw;
        }
    }
}