.description-container {

    .description {
        color: white;
        font-size: 21px;
        line-height: 28px;
        max-width: 725px;
        text-align: center;
    }

    @media (max-width: 600px) {
        .description {
            font-size: 5vw;
        }
    }
}