.login-dialog {
    text-align: center;

    .MuiPaper-root {
        background-color: #1b1c26;
		border: 2px solid #414050;
        color: white;
        max-width: 300px;
    }

    .google-button button {
        width: 200px;
        max-width: 100%;
        margin: 50px 0;

        span {
            font-weight: 700 !important;
            font-size: 16px;
            color: black;
        }
    }

    .privacy {
        font-size: 12px;
        color: #a5a5a5;

        span {
            cursor: pointer;
            text-decoration: underline;
        }

        @media (max-width: 600px) {
            footer {
                font-size: 3.25vw;
            }
        }
    }

}