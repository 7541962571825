.tags-container {
    margin-top: 16px;

    .tags {
        color: white;
        height: 100%;
        background: #3f414e;
        box-shadow: 0 0 10px -1px #08090c;
        text-align: justify;
        padding: 16px;
        overflow-y: auto;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        
        .MuiChip-root {
            margin-top: 12px;
            margin-right: 12px;
            color: white
        }
    }

    .copy-button-container button {
        padding: 4px 15px;
        background-color: #00a4db;
        font-size: 16px;
        border: 0;
        color: white;
        border-radius: 4px;
        font-weight: bold;

        &:hover {
            opacity: 0.9;
        }
    }
}