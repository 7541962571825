.video {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;

    iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
}

@media (max-width: 600px) {
    .video {
        max-width: 100vw;
        padding-bottom: 56vw;
    }
}