.language-selector-component {
    cursor: pointer;

    object svg {
        cursor: pointer !important;
    }

    .main {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #1b1c26;
        border: 1px solid #585858;
        border-radius: 4px;
        padding: 4px 8px;
    }

    .options {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        border: 1px solid #585858;
        background-color: #171825;
        color: white;
        width: 90px;
        border-radius: 4px;
        margin-left: -25px;
        margin-top: 7px;
        display: none;

        &.open {
            display: block  !important;
        }

        & > div {
            width: 100%;
            text-align: center;
            padding: 8px 0;

            &:hover {
                background: #1b1c26;
            }
        }

        svg {
            width: 20px;
            margin-right: 10px;
        }
    }

}