.video-form {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    margin: 50px 0;

    .examples-selector-container {

        & > div {
            margin: 0 0 8px 0;
        }

        #select-example {
            font-weight: bold !important;
        }

        .MuiMenuItem-root {
            font-weight: bold !important;
        }

        label {
            font-weight: bold !important;
        }
        
    }
    

    .input-group .input-group-append button {
        border: 0px;
        color: white;
        font-weight: bold;
        height: 100%;
        border-radius: 0 4px 4px 0;
        
        &:hover {
            opacity: 0.9;
        }
    }

    .MuiSvgIcon-root {
        color: white !important;
    }
    
}