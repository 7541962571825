.descriptions {
    color: white;
    background: #3f414e;
    box-shadow: 0 0 10px -1px #08090c;
    padding: 16px;
    overflow-y: auto;
    border-radius: 4px;

    #flags-select {
        padding: 0px;

        & > button {
            padding: 1px 8px;
            font-size: 16px;
            background: #27282e;
            border: 0;
            color: white;
            font-weight: bold;
            
            &:hover {
                opacity: 0.9;
            }
        }

        ul {
            background: #171825;

            li:hover {
                background-color: #1b1c26;
            }
        }
    }

    .visibility-buttons {
        display: flex;
        flex-direction: row;
        margin-bottom: 16px;
        flex-wrap: wrap;

        & > div {
            margin-right: 12px;
            margin-bottom: 4px;
        }

        #language-selector > .main {
            border: 0;
            background: #27282e;
            padding: 5px 12px;

            &:hover {
                opacity: 0.9;
            }
        }
    }

    .checkbox-button {
        padding: 5px 15px;
        font-size: 16px;
        border: 0;
        color: white;
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
        justify-content: center;
        background: #27282e;
        font-weight: bold;
        cursor: pointer;
        
        &:hover {
            opacity: 0.9;
        }

        & > div {
            width: 12px;
            height: 12px;
            border-radius: 6px;
            margin: 0 6px 0 0;
            border: 0;
            background-color: #00a4db;
        }

        &.disabled > div {
            background-color: #6c757d;
        }
    }

    .copy-button-container button {
        padding: 4px 15px;
        background-color: #00a4db;
        font-size: 16px;
        border: 0;
        color: white;
        border-radius: 4px;
        font-weight: bold;

        &:hover {
            opacity: 0.9;
        }
    }

    .full-space {
        flex: 1;
    }

    .metadata-final-display {
        display: flex;
        flex-direction: column;

        .timestamps span {
            color: #8caaff;
            font-weight: 500;
            cursor: pointer;
        }

        & > div {
            margin-bottom: 16px;
        }

        @media (max-width: 600px) {
            & {
                font-size: 5vw;
                text-align: initial;
            }
        }
    }
}

@media(max-width: 1495px) {
    .descriptions {
        height: auto !important;
    }
}